// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-classes-announcements-js": () => import("./../../../src/pages/classes/announcements.js" /* webpackChunkName: "component---src-pages-classes-announcements-js" */),
  "component---src-pages-classes-ela-js": () => import("./../../../src/pages/classes/ela.js" /* webpackChunkName: "component---src-pages-classes-ela-js" */),
  "component---src-pages-classes-newsletter-js": () => import("./../../../src/pages/classes/newsletter.js" /* webpackChunkName: "component---src-pages-classes-newsletter-js" */),
  "component---src-pages-classes-photos-js": () => import("./../../../src/pages/classes/photos.js" /* webpackChunkName: "component---src-pages-classes-photos-js" */),
  "component---src-pages-classes-reading-js": () => import("./../../../src/pages/classes/reading.js" /* webpackChunkName: "component---src-pages-classes-reading-js" */),
  "component---src-pages-classes-schedule-js": () => import("./../../../src/pages/classes/schedule.js" /* webpackChunkName: "component---src-pages-classes-schedule-js" */),
  "component---src-pages-classes-social-studies-js": () => import("./../../../src/pages/classes/social-studies.js" /* webpackChunkName: "component---src-pages-classes-social-studies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */)
}

